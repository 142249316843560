import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentDivider.figmaUrl.android} codeUrl={checklists.componentDivider.codeUrl.android} checklists={checklists.componentDivider.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`A divider separates your content usually in between top and bottom.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/divider/android-1.png",
      "alt": "usage android divider"
    }}></img>
    <div className="divi" />
    <h2>{`Basic`}</h2>
    <p>{`The LgnDivider is a view that can be used in layouts to separate content into clear groups.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` Make sure to set `}<inlineCode parentName="p">{`android:layout_height="wrap_content"`}</inlineCode>{` on the LgnDivider to ensure that the correct size is set for the divider.`}</p>
    <p>{`The full-bleed divider is displayed below:`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/divider/android-3.png",
      "alt": "divider android"
    }}></img>
    <h3>{`Static in xml`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.dividers.LgnDivider
    android:layout_width="match_parent"
    android:layout_height="wrap_content"
    android:layout_marginTop="@dimen/dimen_16dp" />
`}</code></pre>
    <h3>{`Dynamic using Kotlin`}{`*`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
with(binding) {
    containerBase.addView( //ViewGroup for Dynamic Layout
        LgnDivider(requiredContext()).apply {
            //Your View's customization here
        },
        LinearLayout.LayoutParams( //For example we use viewgroup LinearLayout
            LinearLayout.LayoutParams.MATCH_PARENT,
            LinearLayout.LayoutParams.WRAP_CONTENT
        )
    )
}
...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <p>{`By default, dividers will be full-bleed. You can use `}<inlineCode parentName="p">{`app:dividerInsetStart`}</inlineCode>{` and `}<inlineCode parentName="p">{`app:dividerInsetEnd`}</inlineCode>{` to achieve the look of an inset or middle divider:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.dividers.LgnDivider
    android:layout_width="match_parent"
    android:layout_height="wrap_content"
    app:dividerInsetStart="16dp"
    app:dividerInsetEnd="16dp"/>
`}</code></pre>
    <br />
    <br />
    <h2>{`Integrate with RecyclerView`}</h2>
    <p>{`Integrate divider with your recyclerview`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/divider/android-2.png",
      "alt": "integrate with recyclerview"
    }}></img>
    <h3>{`In your fragment or activity`}</h3>
    <h4>{`Basic Configuration`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
with(binding) {
    //By Default orientation has been set with VERTICAL
    //And without customization view's
    rvSampleCustom.setLegionDivider()
}
...
`}</code></pre>
    <h4>{`Advanced Configuration`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
with(binding) {
    rvSampleCustom.setLegionDivider(
        //Fill with your recyclerview orientation
        //VERTICAL or HORIZONTAL
        orientation = LgnDividerItemDecoration.VERTICAL,
        context = requireContext()
    ) {
        //Your View's customization here

        //Sample customization padding start divider
        dividerInsetStart = 60
        //Sample customization color
        dividerColor = requireContext().resolveColor(R.attr.error_normal) ?: Color.BLACK
    }
}
...
`}</code></pre>
    <InlineNotification kind="info" mdxType="InlineNotification">
      <p><strong parentName="p">{`Info :`}</strong>{` We assume you already have a recyclerview and have already setup it like an adapter setup or a layout manager setup, the example here is just how to integrate your recyclerview with the legion divider component`}</p>
    </InlineNotification>
    <div className="divi" />
    <h2><strong parentName="h2">{`Attributes`}</strong></h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Xml Attrs`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Related method(s)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Thickness`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:dividerThickness`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dividerThickness`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set thickness of dividers directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:dividerColor`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dividerColor`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set dividers color directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Start inset`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:dividerInsetStart`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dividerInsetStart`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set padding start of dividers directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`End inset`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:dividerInsetEnd`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dividerInsetEnd`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set padding end of dividers directly via xml`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      